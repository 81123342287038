import React from 'react'

const Ads = ( { tvPrayer, arabic, langs, fontWeight, isArabicFont } ) => {
    return (
        <div
            style={ {
                maxHeight: "5vh",
                display: 'flex', // Enable flex layout
                justifyContent: 'space-between', // Space between the boxes
                padding: '0 2vh', // Padding on the sides of the container to ensure space between screen edge and boxes
            } }>
            <div className="elements" style={ { width: '100%', marginTop: '2vh' } }>
                <div className="xmoxshadow" id="moonode_top" style={ {
                    flex: 1, // Each box will take equal width
                    borderRadius: '2vh',
                    padding: '0 1vh' // Padding between boxes
                } }>
                    <h1 style={ {
                        fontWeight: fontWeight,
                        fontSize: "2.4vw",
                        textAlign: "center",
                        color: "#001",
                        margin: 0 // Remove default margins
                    } }>
                        <span className={ `${ isArabicFont ? 'arabic' : 'rumi' }` }>
                            { arabic ? langs.shuruk[ Number( tvPrayer.tvLangsOne ) ] : langs.shuruk[ Number( tvPrayer.tvLangsTwo ) ] }
                        </span> : { tvPrayer.apiPrayerTime.Sunrise }
                    </h1>
                </div>
                <div className="xmoxshadow" id="moonode_top" style={ {
                    flex: 1,
                    borderRadius: '2vh',
                    padding: '0 1vh',
                    marginRight: '2vh',
                    marginLeft: '2vh'
                } }>
                    <h1 style={ {
                        fontWeight: fontWeight,
                        fontSize: "2.4vw",
                        textAlign: "center",
                        color: "#001",
                        margin: 0
                    } }>
                        <span className={ `${ isArabicFont ? 'arabic' : 'rumi' }` }>
                            { arabic ? langs.midnight[ Number( tvPrayer.tvLangsOne ) ] : langs.midnight[ Number( tvPrayer.tvLangsTwo ) ] }
                        </span> : { tvPrayer.apiPrayerTime.Midnight }
                    </h1>
                </div>
                <div className="xmoxshadow" id="moonode_top" style={ {
                    flex: 1,
                    borderRadius: '2vh',
                    padding: '0 1vh'
                } }>
                    <h1 style={ {
                        fontWeight: fontWeight,
                        fontSize: "2.4vw",
                        textAlign: "center",
                        color: "#001",
                        margin: 0
                    } }>
                        <span className={ `${ isArabicFont ? 'arabic' : 'rumi' }` }>
                            { arabic ? langs.thirdnight[ Number( tvPrayer.tvLangsOne ) ] : langs.thirdnight[ Number( tvPrayer.tvLangsTwo ) ] }
                        </span> : { tvPrayer.apiPrayerTime.Imsak }
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default Ads
