import React, { useState, useEffect, lazy, Suspense } from 'react'
import { calculeNextIqama, whatIsNext } from '../../../services/formatDate.js';
import Top from './TopLeft&Righ/Index';
import Rumi from '../../../layouts/prayerTV/Rumi/PrayerRoumi.js';
import Video from '../../../layouts/prayerTV/Video/Index.js';
import Footer from '../../../layouts/prayerTV/Footer/Index.js'
const FooterDown = lazy( () => import( '../../../layouts/footer' ) );

export default function Index ( {
    tvPrayer,
    color,
    adhan,
    iqama,
    setAdhan,
    setIqama,
    langs,
    time,
    token,
    arabic,
    switchLangue,
    turningOffAds,
    reload,
    setTvNotification,
    setTvNotificationAndIqama,
    tvNotification,
    isTvNotification,
} ) {
    const [ show, setShow ] = useState( false )
    const [ dimensions, setdimensions ] = useState( {
        width: 0,
        height: 0
    } )
    useEffect( () => {
        setTimeout( () => {
            setShow( true )
        }, 50 );
        updateWindowDimensions();
        window.addEventListener( 'resize', updateWindowDimensions );

        return () => {
            window.removeEventListener( 'resize', updateWindowDimensions );
        }
    }, [] )

    const updateWindowDimensions = () => {
        setdimensions( { width: window.innerWidth, height: window.innerHeight } );
    }

    const wichColorBackGround = ( color ) => {
        switch ( color?.toLowerCase() ) {
            case "#16acea":
                return " moonode_background_blue";
            case "#79c300":
                return " moonode_background_green";
            case "#ee204d":

                return " moonode_background_red";
            case "#ffa500":

                return " moonode_background_orange";
            case "#d902ee":

                return " moonode_background_purple";
            case "#fcd45c":

                return " moonode_background_yellow";
            case "#000000":
                return " moonode_background_black";
            default:
                return " moonode_background_blue";
        }
    }
    const wichColorHeightLight = ( color ) => {
        switch ( color ) {
            case "#16acea":
                return " moonode_heighlight_blue";
            case "#79C300":
                return " moonode_heighlight_green";
            case "#ee204d":
                return " moonode_heighlight_red";
            case "#FFA500":
                return " moonode_heighlight_orange";
            case "#d902ee":
                return " moonode_heighlight_purple";
            case "#fcd45c":
                return " moonode_heighlight_yellow";
            case "#000000":
                return " moonode_heighlight_black";
            default:
                return " moonode_heighlight_blue";
        }
    }
    const wichColorText = ( color ) => {
        switch ( color ) {

            case "#ee204d":
                return "#fff";

            case "#fcd45c":
                return "#fff";
            case "#000000":
                return "#fff";
            case "#79C300":
                return "#fff";
            case "#FFA500":
                return "#fff";
            default:
                return "black";
        }
    }

    const isArabicFont = ( arabic, tv ) => {
        const { tvLangsTwo, tvLangsOne } = tv;
        let isArabic = false;
        if ( tvLangsTwo, tvLangsOne ) {
            if ( arabic &&
                ( ( Number( tvLangsOne ) === 1 ) || ( Number( tvLangsOne ) === 3 ) ) ) {
                isArabic = true;
            } else if ( !arabic && ( ( Number( tvLangsTwo ) === 3 ) || ( Number( tvLangsTwo ) === 1 ) ) ) {
                isArabic = true;
            }
        }
        return isArabic;

    }
    return (
        <div className={ `elementToFadeInAndOut ${ wichColorBackGround( color ) }` } >
            { adhan || iqama ? <Video
                tvPrayer={ tvPrayer }
                isAdhan={ adhan }
                setTvNotificationAndIqama={ setTvNotificationAndIqama }
                setAdhan={ setAdhan }
                setIqama={ setIqama }
                setTvNotification={ setTvNotification }
                tvNotification={ tvNotification }
                isTvNotification={ isTvNotification } /> : null }
            { adhan || iqama ? null :
                show && <>
                    <Top isArabicFont={ isArabicFont( arabic, tvPrayer ) } color={ wichColorHeightLight( color ) } textColor={ wichColorText( color ) } setTvNotification={ setTvNotification } reload={ reload } manualInterval={ time } tvPrayer={ tvPrayer } langs={ langs } token={ token } arabic={ arabic } whatIsNext={ whatIsNext } setIqama={ setIqama } setAdhan={ setAdhan } iqama={ iqama } adhan={ adhan } switchLangue={ switchLangue } turningOffAds={ turningOffAds } />
                    <Rumi isArabicFont={ isArabicFont( arabic, tvPrayer ) } tvPrayer={ tvPrayer } langs={ langs } arabic={ arabic } calculeNextIqama={ calculeNextIqama } iqama={ iqama } color={ wichColorHeightLight( color ) } >
                        <Footer isArabicFont={ isArabicFont( arabic, tvPrayer ) } webSite={ tvPrayer.entity.website } announcement={ tvPrayer.announcement } isOnline={ tvPrayer.isOnline } fontWeight={ tvPrayer.tvSettings.fontWeight } />
                        <Suspense fallback={ <></> }>
                            <span style={ { marginTop: "1vh" } } />
                            <FooterDown dinscreen={ false } screenName="2024 September 6 version" white={ true } />
                        </Suspense>
                    </Rumi>
                </>
            }
        </div>
    )
}

