import React from 'react';
import GlassedBox from '../glassmorphism/boxGlassed'; // Adjust the import path as needed

const ImageBox = ( { imageUrl, height, imageStyle, boxStyle } ) => {
    // Extracted styles into variables
    const computedBoxStyle = {
        height: height,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: '2vh',
        borderTopRightRadius: '2vh',
        ...boxStyle
    };

    const computedImageStyle = {
        ...imageStyle,
        borderRadius: '2vh',
    };

    return (
        <GlassedBox
            gmode={ 'gm-dmode' }
            styles={ computedBoxStyle }
        >
            <img
                src={ imageUrl }
                alt="image"
                style={ computedImageStyle }
            />
        </GlassedBox>
    );
};

export default ImageBox;
