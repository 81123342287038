// import 'core-js/es/map';
// import 'core-js/es/set';
// import 'raf/polyfill';
// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// import 'core-js/actual/array/from';       // <- at the top of your entry point
// import 'core-js/actual/array/group';      // <- at the top of your entry point
// import 'core-js/actual/set';              // <- at the top of your entry point
// import 'core-js/actual/promise';          // <- at the top of your entry point
// import 'core-js/actual/structured-clone'; // <- at the top of your entry point
// import 'core-js/actual/queue-microtask';
import 'regenerator-runtime/runtime';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/actual/array/from';
import 'core-js/actual/array/group';
import 'core-js/actual/set';
import 'core-js/actual/promise';
import 'core-js/actual/structured-clone';
import 'core-js/actual/queue-microtask';
import 'core-js/es/array/includes';
import 'core-js/es/array/from';
import 'core-js/es/object/assign';
import 'core-js/es/string/includes';
import 'core-js/es/promise';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';
import 'core-js/es/weak-set';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
