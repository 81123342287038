import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import GlassedBox from '../../../../layouts/glassmorphism/boxGlassed';
import ImageBox from '../../../../layouts/ImageBox';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

import {
    getIsIqama,
    getQrCode,
    getQibla,
    getLogo,
} from '../../../../services/selectors/prayersSelectors';
import CustomSkeletonLoader from '../../../../layouts/skeletonLoader';

const LogoModern = ( { style } ) => {
    const isIqama = useSelector( getIsIqama );
    const qrCode = useSelector( getQrCode );
    const logo = useSelector( getLogo );
    const qibla = useSelector( getQibla );
    const createSlides = useCallback( () => {
        const items = [];

        if ( isIqama ) {
            items.push(
                <SwiperSlide key="entity-logo" style={ { width: "57vh" } }>
                    <ImageBox
                        imageUrl={ logo }
                        height="100%"
                        imageStyle={ {
                            objectFit: 'fill',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                        } }
                        boxStyle={ {
                            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',

                        } }
                    />
                </SwiperSlide>
            );
        }

        items.push(
            <SwiperSlide key="down-image" style={ { width: "57vh" } }>
                <ImageBox
                    imageUrl={ qibla }
                    height="100%"
                    imageStyle={ {
                        objectFit: 'contain',
                        width: '120%',
                        height: '120%',
                        alignContent: 'center',
                        display: 'block'
                    } }
                />
            </SwiperSlide>
        );

        if ( isIqama ) {
            items.push(
                <SwiperSlide key="qr-code" style={ { width: "57vh" } }>
                    <ImageBox
                        imageUrl={ qrCode }
                        height="100%"
                        imageStyle={ {
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            borderRadius: '2vh',
                            backgroundColor: 'white',
                        } }
                    />
                </SwiperSlide>
            );
        }

        return items;
    }, [ qibla, isIqama ] );

    return (
        <GlassedBox
            styles={ {
                margin: '0.5vh',
                width: !isIqama ? '50%' : '25%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '2vh',

                marginLeft: '0.5vh',
                ...style
            } }
        >
            { !qibla ? <CustomSkeletonLoader width="100%" height="100%" /> :
                <Swiper
                    className='gm-dmode'
                    lazy={ true }
                    preloadImages={ false }
                    modules={ [ Autoplay ] }
                    slidetoshow={ 2 }
                    style={ { width: "100%", height: "100%", borderRadius: "2vh", backgroundColor: "transparent" } }
                    autoplay={ {
                        delay: 64000, //after jumua
                        disableOnInteraction: false
                    } }
                    slidesPerView={ 1 }
                    speed={ 1250 }>
                    { createSlides() }
                </Swiper> }
        </GlassedBox>
    );
};

export default React.memo( LogoModern );
