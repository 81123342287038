export const SET_ALERT = "SET_ALERT";
export const POST_SALAT = "POST_SALAT";
export const GET_TV_CODE = "GET_TV_CODE";
export const GET_PRAYERS = "GET_PRAYERS";
export const SET_FETCHING = "SET_FETCHING";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_SOME_POSTS = "SET_SOME_POSTS";
export const SET_DARK_MODE_APP = "SET_DARK_MODE_APP";
export const SET_FOCUS_MODE_APP = "SET_FOCUS_MODE_APP";
export const SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING";
export const GET_ADMIN_PRAYER_TV = "GET_ADMIN_PRAYER_TV";
export const POST_ADMIN_PRAYER_TV = "POST_ADMIN_PRAYER_TV";
export const SET_ADVERTISMENT_APP = "SET_ADVERTISMENT_APP";
export const SET_DARK_MODE_APP_INIT = "SET_DARK_MODE_APP_INIT";

//state app
export const SET_SHURUQ = "SET_SHURUQ";
export const SET_IQAMA_APP = "SET_IQAMA_APP";
export const SET_ADHAN_APP = "SET_ADHAN_APP";
export const SET_THIRD_NIGHT = "SET_THIRD_NIGHT";
export const SET_MIDDLE_NIGHT = "SET_MIDDLE_NIGHT";

//player
export const PLAY_MEDIA = 'PLAY_MEDIA';
export const STOP_MEDIA = 'STOP_MEDIA';
export const NEXT_MEDIA = 'NEXT_MEDIA';
export const PAUSE_MEDIA = 'PAUSE_MEDIA';
export const TOGGLE_LOOP = 'TOGGLE_LOOP';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const TOGGLE_PLAY = 'TOGGLE_PLAY';
export const LOAD_PLAYLIST = 'LOAD_PLAYLIST';
export const CLEAR_PLAYLIST = 'CLEAR_PLAYLIST';
export const PREVIOUS_MEDIA = 'PREVIOUS_MEDIA';
export const TOGGLE_LOOPING = 'TOGGLE_LOOPING';
export const TOGGLE_AUTOPLAY = 'TOGGLE_AUTOPLAY';
export const INCREASE_VOLUME = 'INCREASE_VOLUME';
export const DECREASE_VOLUME = 'DECREASE_VOLUME';
export const RESET_MEDIA_INDEX = 'RESET_MEDIA_INDEX';
export const UPDATE_CURRENT_MEDIA = 'UPDATE_CURRENT_MEDIA';
export const LOAD_PLAYLIST_EXTERNAL = 'LOAD_PLAYLIST_EXTERNAL';
export const ENABLE_EXTERNAL_CONTROL = 'ENABLE_EXTERNAL_CONTROL';
export const DISABLE_EXTERNAL_CONTROL = 'DISABLE_EXTERNAL_CONTROL';

//languages
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';


//Flyer

export const GET_FLYER = 'GET_FLYER'