import React from 'react';
import Adhan from './Adhan';
import Iqama from './Iqama';

const PrayerRoumi = ( { children, tvPrayer, arabic, calculeNextIqama, iqama, langs, color, isArabicFont } ) => {
    return (
        <div className="appLayout">
            <div className="prayerTimesContainer" style={ { margin: '1vh' } }>
                <div className="container-x">
                    { [ 'isha', 'maghrib', 'asr', 'dhuhr', 'fajr', 'salat' ].map( ( prayer, index ) => (
                        <div className="title-prayers" key={ index } id="xmoxshadow" style={ { flex: '1', opacity: "80%", borderRadius: '1.5vh', margin: '0vh 0.5vh 0.5vh 0.5vh', } }>
                            <div className="content">
                                <h1 className={ `${ isArabicFont ? 'arabic' : 'rumi' }` }
                                    style={ { marginTop: '3.5vh', textAlign: "center", fontWeight: 500, color: "#3a3a3a" } }>
                                    { arabic ? langs[ prayer ][ Number( tvPrayer.tvLangsOne ) ] : langs[ prayer ][ Number( tvPrayer.tvLangsTwo ) ] }
                                </h1>
                            </div>
                        </div>
                    ) ) }
                </div>
                <div style={ { marginBottom: "-0.5vh", marginTop: '-0.5vh' } } >
                    <Adhan tvPrayer={ tvPrayer } isArabicFont={ isArabicFont } arabic={ arabic } calculeNextIqama={ calculeNextIqama } iqama={ iqama } langs={ langs } color={ color } />
                    <Iqama tvPrayer={ tvPrayer } isArabicFont={ isArabicFont } arabic={ arabic } calculeNextIqama={ calculeNextIqama } iqama={ iqama } langs={ langs } color={ color } />
                </div>
                <span style={ { marginBottom: "1vh" } } />
                { children }
            </div>
        </div>
    );
};

export default PrayerRoumi;
