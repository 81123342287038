import React from 'react'
const Next = ( { whatIsNext, tvPrayer, langs, arabic, color, show = true, textSize = "3.5vw", fontWeight, isArabicFont } ) => {
    const minHours = ( timeToIqama, timeToAdhan, iqamaTimer ) => {
        try {
            //var 
            let fajrIn = arabic ? langs.fajrIn[ Number( tvPrayer.tvLangsOne ) ] : langs.fajrIn[ Number( tvPrayer.tvLangsTwo ) ]
            let iqamaFajrIn = arabic ? langs.iqamaFajrIn[ Number( tvPrayer.tvLangsOne ) ] : langs.iqamaFajrIn[ Number( tvPrayer.tvLangsTwo ) ]
            let h = arabic ? langs.h[ Number( tvPrayer.tvLangsOne ) ] : langs.h[ Number( tvPrayer.tvLangsTwo ) ]
            let min = arabic ? langs.minutes[ Number( tvPrayer.tvLangsOne ) ] : langs.minutes[ Number( tvPrayer.tvLangsTwo ) ]

            if ( timeToIqama > 0 ) {
                //is the timer on
                if ( iqamaTimer ) {
                    //show the iqama IN event  
                    return iqamaFajrIn + ( timeToIqama < 60 ? timeToIqama : new Date( timeToIqama * 1000 ).toISOString().substr( 14, 5 ) )
                } else {
                    // if we are before the ADHAN and LT than 60 minutes 
                    if ( timeToAdhan < 3600 ) {
                        return fajrIn + ( timeToAdhan < 60 ? timeToAdhan : ( timeToAdhan < 3600 ? new Date( timeToAdhan * 1000 ).toISOString().substr( 14, 5 ) : ( new Date( timeToAdhan * 1000 ).toISOString().substr( 11, 5 ) ) ).replace( ":", min ) )
                    } else {
                        // if we are GT 60 minutes we show fajr IN with hours 
                        return fajrIn + ( timeToAdhan < 60 ? timeToAdhan : ( timeToAdhan < 3600 ? new Date( timeToAdhan * 1000 ).toISOString().substr( 14, 5 ) : ( new Date( timeToAdhan * 1000 ).toISOString().substr( 11, 5 ) ) ).replace( ":", h ) )
                    }
                }
            } else {
                if ( iqamaTimer ) {
                    return iqamaFajrIn + ( timeToIqama + ( 24 * 60 * 60 ) < 60 ? timeToIqama + ( 24 * 60 * 60 ) : new Date( ( timeToIqama + ( 24 * 60 * 60 ) ) * 1000 ).toISOString().substr( 14, 5 ) )
                } else {
                    return fajrIn +
                        ( timeToAdhan + ( 24 * 60 * 60 ) < 60 ? timeToAdhan + ( 24 * 60 * 60 ) :
                            ( timeToAdhan + ( 24 * 60 * 60 ) < 3600 ? new Date( ( timeToAdhan + ( 24 * 60 * 60 ) ) * 1000 ).toISOString().substr( 14, 5 ) : ( new Date( ( timeToAdhan + ( 24 * 60 * 60 ) ) * 1000 ).toISOString().substr( 11, 5 ) ).replace( ":", h ) ) )
                }
            }
        } catch ( error ) {
            return "Loading"
        }
    }

    const whichSalat = ( salat, iqamaTimer ) => {
        switch ( salat ) {
            case "DHUHR":
                if ( iqamaTimer ) {
                    return arabic ? langs.iqamaDhuhrIn[ Number( tvPrayer.tvLangsOne ) ] : langs.iqamaDhuhrIn[ Number( tvPrayer.tvLangsTwo ) ]
                } else {
                    return arabic ? langs.dhuhrIn[ Number( tvPrayer.tvLangsOne ) ] : langs.dhuhrIn[ Number( tvPrayer.tvLangsTwo ) ]
                }

            case "ASR":
                if ( iqamaTimer ) {
                    return arabic ? langs.iqamaAsrIn[ Number( tvPrayer.tvLangsOne ) ] : langs.iqamaAsrIn[ Number( tvPrayer.tvLangsTwo ) ]
                } else {
                    return arabic ? langs.asrIn[ Number( tvPrayer.tvLangsOne ) ] : langs.asrIn[ Number( tvPrayer.tvLangsTwo ) ]
                }

            case "MAGHRIB":
                if ( iqamaTimer ) {
                    return arabic ? langs.iqamaMaghribIn[ Number( tvPrayer.tvLangsOne ) ] : langs.iqamaMaghribIn[ Number( tvPrayer.tvLangsTwo ) ]
                } else {
                    return arabic ? langs.maghribIn[ Number( tvPrayer.tvLangsOne ) ] : langs.maghribIn[ Number( tvPrayer.tvLangsTwo ) ]
                }

            case "ISHA":
                if ( iqamaTimer ) {
                    return arabic ? langs.iqamaIshaIn[ Number( tvPrayer.tvLangsOne ) ] : langs.iqamaIshaIn[ Number( tvPrayer.tvLangsTwo ) ]
                } else {
                    return arabic ? langs.ishaIn[ Number( tvPrayer.tvLangsOne ) ] : langs.ishaIn[ Number( tvPrayer.tvLangsTwo ) ]
                }

            default:
                return " "

        }
    }

    const findTime = ( data ) => {
        let h = arabic ? langs.h[ Number( tvPrayer.tvLangsOne ) ] : langs.h[ Number( tvPrayer.tvLangsTwo ) ]
        try {
            if ( data && data.salat === "Fajr" ) {
                return minHours( data.timeToIqama, data.timeToAdhan, data.iqamaTimer )
            } else {
                if ( data.iqamaTimer ) {
                    return whichSalat( data.salat.toUpperCase(), data.iqamaTimer ) + ( data.timeToIqama < 60 ? data.timeToIqama : ( data.timeToIqama < 3600 ? new Date( data.timeToIqama * 1000 ).toISOString().substr( 14, 5 ) : ( new Date( data.timeToIqama * 1000 ).toISOString().substr( 11, 5 ) ).replace( ":", h ) ) )
                } else {
                    return whichSalat( data.salat.toUpperCase(), data.iqamaTimer ) + ( data.timeToAdhan < 60 ? data.timeToAdhan : ( data.timeToAdhan < 3600 ? new Date( data.timeToAdhan * 1000 ).toISOString().substr( 14, 5 ) : ( new Date( data.timeToAdhan * 1000 ).toISOString().substr( 11, 5 ) ).replace( ":", h ) ) )
                }
            }
        } catch ( error ) {
            return "..."
        }
    }
    return (
        <div className={ `box-extra ${ ( color ) }` } style={ { maxHeight: !show ? "25vh" : "150px", position: "inherit", bottom: "0px" } } id="xmoxshadow">
            <h1 style={ { fontSize: `${ findTime( whatIsNext( tvPrayer.iqamaTime, tvPrayer.apiPrayerTime ) ).indexOf( "Maghrib" ) !== -1 ? "3vw" : textSize }`, color: "#fff", textAlign: "center", fontWeight: fontWeight } }>
                <span className={ ` ${ isArabicFont ? "arabic" : "" }` } >
                    { findTime( whatIsNext( tvPrayer.iqamaTime, tvPrayer.apiPrayerTime ) ) }
                </span>
            </h1>
        </div>
    )
}


export default Next
