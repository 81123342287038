import React from 'react';
import Tv from '../../views/wrappers/blue';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPlaying } from '../../services/selectors/playerSelectors';
const View = ( { ads = true } ) => {
    const { token, time, reload } = useParams();
    const dispatch = useDispatch();
    const playing = useSelector( getPlaying );
    return <Tv token={ token } time={ time } reload={ reload } ads={ ads } dispatch={ dispatch } playing={ playing } />;
};

export default View;
